import { graphql, useStaticQuery } from "gatsby"
import React, { useEffect, useState } from "react"
// import Img from 'gatsby-image'
import BackgroundImage from "gatsby-background-image"

export default function Masthead({ description, title, homepage }) {
  const [nextBackground, setNextBackground] = useState()

  const data = useStaticQuery(graphql`
    query MastheadStaticQuery {
      file(relativePath: { eq: "header-bg.jpg" }) {
        childImageSharp {
          fluid {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `)

  // console.log('data in masthead:', data);

  const placeholder1900c1250 = data.file.childImageSharp.fluid

  useEffect(() => {
    setNextBackground(placeholder1900c1250)
  }, [placeholder1900c1250])

  return (
    <BackgroundImage
      Tag="section"
      fluid={nextBackground}
      backgroundColor={`#040e18`}
    >
      <header className="masthead">
        <div className="container">
          <div className="masthead-heading text-uppercase">{title}</div>
          {homepage !== undefined && (
            <div className="masthead-subheading">
              <a href={homepage} target="_blank" rel="noreferrer">
                {homepage}
              </a>
            </div>
          )}
          {/* <a
            className="btn btn-primary btn-xl text-uppercase js-scroll-trigger"
            href="#services"
          >
            Tell Me More
          </a> */}
          {/* <Img fluid={asdf} /> */}
        </div>
      </header>
    </BackgroundImage>
  )
}
